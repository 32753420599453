<template>
  <div class="page">
    <div class="poster" style="margin-bottom: 0">
      <img src="@/assets/2025/Berget-21-header-banner.jpg" />
    </div>
    <div class="poster" style="max-width: 1280px; margin: auto; margin-bottom: 60px;" v-if="0">
      <div style="padding:56.25% 0 0 0;position:relative; width: 100%;">
        <iframe src="https://player.vimeo.com/video/884900401?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="B20teaserfinal"></iframe>
        <script type="application/javascript" src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </div>

    <div class="segment bg-brown">
      <div class="content narrow">
        <div class="post no-bg">
          <div class="post-content-wrapper big-text">
            <div class="post-content centered-image">
            </div>
            <div class="post-header text-highlight-red">BERGET 21 <span style="color:white"> - SISTERS OF THE SERPENT</span></div>
            <pre class="post-content">
              Berget game areas have diverse and hilly forests that provide the opportunity for fantastic airsoft combat. This year we will partially go back to the roots of our older Berget games by placing some objects and missions deeper into the forest outside the road network to create the opportunity for new kinds of battles and ambushes where we utilize the great airsoft terrain.

              We are working to update several rules according to the great feedback from the community where one example is to increase the reload time on anti-tank weapons and again slightly increase hit percentage on all vehicles. There are many changes that come from the feedback survey that many of you participated in the last few weeks. A more extensive presentation of both the results of the survey, and our short/long term actions will be posted soon.

              For Berget 21 there will be a monastery built on the game area which will be populated by over 20 nuns. The nuns are high value targets for all teams that they will try to find, rescue or kill/capture depending on your team's main objective. All nuns will be played by professional female athletes with the instruction to avoid capture. It is our goal that this new “living currency” will add a more dynamic and interesting flow to the game.

              At the start of the game one of the teams will have a new kind of challenge that is a first for any Berget game. There will be an amphibious landing where the team's first task is to cross a lake and make landings with small motorized boats to establish a bridgehead for their soldiers. Ofcourse safety will be our biggest focus and safety instructions and training will be issued to this faction before the landing and it will all be overseen by a dedicated and trained security team. This is a much requested feature that we are trying out this year to see if the amphibious elements are worth building on for future events.

              <b>All tickets to Berget 21 will be released HERE on Sunday the 17th of November 2024 20:00 CET (GMT+01).</b> The tickets will be sold at a 30€ discount the first night to the price of 159€. After the first night the ticket price will be raised to 189€, and from March it will be 220€. We are doing this to reward those who buy their tickets early which help us plan and make a better Berget for all.
            </pre>
            <div class="post-date">2024.10.27 - 20:00 CET (GMT +1) </div>
          </div>
        </div>
      </div>
    </div>

    <div class="segment" v-if="news.length > 0">
      <div class="content">
        <div class="header">LATEST <span class="text-highlight">NEWS</span></div>

        <div class="post" v-for="post in news" :key="post.Id">
          <div v-if="post.Cover.length > 0" class="image"><img :src="getImageURL(post)"></div>
          <div class="post-content-wrapper">
            <div class="post-header">{{ post.Title }}</div>
            <pre class="post-content" v-html="post.Content"></pre>
            <div class="post-date">{{ formatDate(post.Date) }}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      news: [{
        Id: '8',
        Title: 'Games Rules update 2025',
        Cover: [{ path: 'img/rulesupdate.jpg' }],
        Content: `We have now released 2025's game and chrono rule updates.

        Go to info > rules and download these, below are some of the changes and updates.


        <b>Rule Changes 2025</b>

        The wounded flag -  You must now also bring an orange vest to Berget to use during darker hours when it's hard to see the normal wounded flag.

        Armband - Clarification on the importance of the armband and what you must do if you lose it during battle. It is a clear rule violation not to wear an armband or if the armband number is not visible. Make sure your armband is visible at all times!

        AT4 - Increased reload time and lowered damage against vehicles. Updated function description.  \t

        Stingers - Description added, lowered overall damage.

        Anti vehicle obstacles - Rules added. You may not use debris or branches as simple roadblocks.

        Boats - Rules and description added. Boats will be part of the game, make sure you read about them.

        Trustees - We are reintroducing player trustees. Some trusted players and veterans get trustee status, Crew-education and can act as neutral GMs with yellow Berget crew vest.

        Base drill sergeant - Introducing a new Crew role to check players in base pre game by addressing minor rule violations such as correct armbands, magazine capacity and random chrono tests.

        Vehicle classes and hit points - Updated and clarified.


        <b>Chrono changes 2025</b>

        Rate of fire - The rate of fire for assault weapons is restricted to 20 rounds/second and for machine guns 25 rounds/second.

        Class 7 Turret weapons - The turret guns moved into a new weapons class with some benefits and restrictions.

        Full thrust from Novritsch will be allowed under class 5 sniper
        `,
        Date: '2025-03-03 00:30'
      }, {
        Id: '7',
        Title: 'Commander Applications!',
        Cover: [{ path: 'img/commander.jpg' }],
        Content: `The time has come to apply for main commander for REPA, Coalition Forces and Resistance!

        If you want to apply for this tough but important job as main commander; Please send an application to <a href="mailto:info@berget-events.com?subject=Main%20Commander">info@berget-events.com</a>, and have "Main Commander" as subject.

        Enclose the following:

        • Name
        • Nickname
        • Age
        • A short private resume regarding your civilian life and job.
        • Any military or leadership experience.
        • Your previous Berget experience.
        • Present an example of a HQ Crew setup
        • A short presentation on your thoughts about the upcoming Berget and how you will carry out your command.

        We are closing applications for main commanders December 8th.

        Good luck!
        `,
        Date: '2024-11-24 21:35'
      }, {
        Id: '6',
        Title: 'Tickets Released!',
        Cover: [{ path: 'img/ticket.jpg' }],
        Content: `The tickets for Berget 21 are now released!

        <a style="font-size: 3em; color: #E64020" href="https://berget-portal.com/" class="buy-tickets-here">BUY TICKETS HERE!</a>
        `,
        Date: '2024-11-17 20:00'
      }, {
        Id: '5',
        Title: 'Ticket Update!',
        Cover: [{ path: 'img/ticketUpdate.jpg' }],
        Content: `We are aware that the current economic climate is hard on many people and after some good feedback we have decided to prolong the normal 30 day payment time of our tickets up to 60 days. We will also prolong the second ticket price step by a few months.
To sum it up, tickets are released at the discounted price of 159€ the first night of 17th November 20:00 CET. After the first night all tickets will cost 189€.  All tickets booked have up to 60 days to be paid.`,
        Date: '2024-11-14 10:55'
      }],
      newsExample: []
    }
  },
  methods: {
    async loadNews () {
      const response = await fetch('https://berget-events.com/db/news')
      const result = await response.json()
      result.list.sort((a, b) => {
        return a.Id > b.Id ? -1 : 1
      })
      this.news = result.list
    },
    formatDate (date) {
      const time = date.substring(10, 16) + ' CET (GMT +1)'
      const prettyDate = date.substring(0, 10).replace(/-/g, '.')
      return `${prettyDate} - ${time}`
    },
    getImageURL (post) {
      const img = post.Cover[0] || {}
      if (img.url) return img.url
      if (img.path) return img.path.replace('download/', 'https://berget-events.com/uploads/')
    }
  },
  created () {
    this.loadNews()
  }
}
</script>
